$(document).ready(function () {

    $('.icare-questions-products .product .icon-plus-circle').click(function () {
        var $product = $(this).closest('.product');
        if ($product.closest('.jspContainer').hasClass('other-products')) {
            var otherProduct = {
                code: $product.data('desc'),
                name: $product.data('name'),
                price: $product.data('price')
            }
            var $selectProduct = $('#other-product-template-container .product').clone(true);
            $selectProduct.find('input[name="suggestedOtherProducts[]"]').val(JSON.stringify(otherProduct));
            $selectProduct.find('.name').html($product.data('name'));
            $selectProduct.find('.desc').html($product.data('desc'));
            if ($product.data('price') != '') {
                $selectProduct.find('.price').html($product.data('price') + ' €');
            }
            $selectProduct.find('.trash-btn').click(function () {
                $(this).closest('.product').remove();
            });
        } else if ($product.closest('.products-list').hasClass('treatments-list')) {
            var $selectProduct = $('#treatment-template-container .product').clone(true);
            $selectProduct.find('input[name="suggestedTreatmentIds[]"]').val($product.data('id'));
            $selectProduct.find('.name').html($product.data('name'));
            $selectProduct.find('.desc').html($product.find('.desc').html());
            $selectProduct.find('.trash-btn').click(function () {
                $(this).closest('.product').remove();
            });
        } else {
            if ($product.data('promoter') == 'difacooper' || $product.data('is-pre-selected') == true) {
                var $selectProduct = $('#other-product-template-container .product').clone(true);
                var otherProduct = {
                    code: $product.data('desc'),
                    name: $product.data('name'),
                    price: $product.data('price')
                }
                $selectProduct.find('input[name="suggestedOtherProducts[]"]').val(JSON.stringify(otherProduct));
            } else {
                var $selectProduct = $('#product-template-container .product').clone(true);
                $selectProduct.find('input[name="suggestedBusinessProductIds[]"]').val($product.data('id'));
            }
            $selectProduct.find('.name').html($product.data('name'));
            $selectProduct.find('.desc').html($product.data('desc'));
            if ($product.data('price') != '') {
                $selectProduct.find('.price').html($product.data('price') + ' €');
            }
            $selectProduct.find('.trash-btn').click(function () {
                $(this).closest('.product').remove();
            });
        }

        $(this).closest('.products-list').find('.products-selected .list').append($selectProduct);
    });

    $('.confirm').click(function () {
        var exportButton = $(this);
        bootbox.confirm(translations['interface.icare4.messages.confirm'], function (result) {
            if (result === true) {
                window.location.href = exportButton.attr('data-href');
            }
        });
    });

    $('form:not(.no-validate)').submit(function () {
        var submitButtons = $(this).find('input[type="submit"], button[type="submit"]');

        submitButtons.each(function () {
            $(this).attr('disabled', 'disabled');
        });

        var valid = $(this).valid();
        if (!valid) {
            submitButtons.each(function () {
                $(this).removeAttr('disabled');
            });
        }

        if (valid && typeof afterValidation === "function") {
            valid = afterValidation();
        }

        return valid;
    });

    $('.datepicker').on('click', function (event) {
        $input = $(this).pickadate({
            close: translations['interface.icare4.buttons.cancel'],
            clear: translations['interface.icare4.buttons.reset'],
            today: translations['interface.icare4.today'],
            monthsFull: [translations['interface.icare4.months.january'], translations['interface.icare4.months.february'], translations['interface.icare4.months.march'], translations['interface.icare4.months.april'], translations['interface.icare4.months.may'], translations['interface.icare4.months.june'], translations['interface.icare4.months.july'], translations['interface.icare4.months.august'], translations['interface.icare4.months.september'], translations['interface.icare4.months.october'], translations['interface.icare4.months.november'], translations['interface.icare4.months.december']],
            weekdaysShort: [translations['interface.icare4.calendar.weekdays.sun'], translations['interface.icare4.calendar.weekdays.mon'], translations['interface.icare4.calendar.weekdays.tue'], translations['interface.icare4.calendar.weekdays.wes'], translations['interface.icare4.calendar.weekdays.thu'], translations['interface.icare4.calendar.weekdays.fri'], translations['interface.icare4.calendar.weekdays.sat']],
            selectMonths: true,
            language: "it",
            selectYears: 99,
            formatSubmit: 'dd-mm-yyyy',
            format: 'dd-mm-yyyy',
            editable: 'true',
            onClose: function () {
                $(this).focus();
            }
        })

        picker = $input.pickadate('picker');

        if (picker.get('open')) {
            picker.close();
        } else {
            picker.open();
        }
        event.stopPropagation();
    });



    var validateDatetime = function (t, e) {
        var i = !1,
            a = /^\d{1,2}\/\d{1,2}\/\d{4} \d{2}:\d{2}$/;
        if (a.test(t)) {
            var items = t.split(" ");
            var r = items[0].split("/"),
                n = parseInt(r[0], 10),
                s = parseInt(r[1], 10),
                u = parseInt(r[2], 10);
            var x = items[1].split(":"),
                y = parseInt(x[0], 10),
                z = parseInt(x[1], 10);
            var d = new Date(u, s - 1, n, y, z, 0, 0);

            i = d.getFullYear() === u && d.getMonth() === s - 1 && d.getDate() === n && d.getHours() === y && d.getMinutes() === z ? !0 : !1;
        } else if (t == 'ND') {
            i = 1;
        } else
            i = !1;
        return this.optional(e) || i
    };
    var validateDate = function (t, e) {
        var i = !1,
            a = /^\d{1,2}-\d{1,2}-\d{4}$/;
        if (a.test(t)) {
            var r = t.split("-"),
                n = parseInt(r[0], 10),
                s = parseInt(r[1], 10),
                u = parseInt(r[2], 10),
                d = new Date(u, s - 1, n);
            i = d.getFullYear() === u && d.getMonth() === s - 1 && d.getDate() === n ? !0 : !1;
        } else if (t == 'ND') {
            i = 1;
        } else
            i = !1;
        return this.optional(e) || i
    };
    var validateCurrency = function (t, e) {
        a = /^\d*(\.\d{0,2}){0,1}$/;
        return a.test(t) || t == 'ND';
    }
    var validateEqual = function (t, e) {
        return false;
    }
    var validateCurrencyNice = function (t, e) {
        a = /^(\d{1,3}\.){1,}\d{3}(,(\d{2}))?$|^\d*(,(\d{2}))?$/;
        return a.test(t) || t == 'ND';
    }
    var validateTelephone = function (t, e) {
        a = /^\d*$/;
        b = /^ANONIMO$/;
        c = /^anonimo$/;
        d = /^Anonimo$/;

        return a.test(t) || b.test(t) || c.test(t) || d.test(t);
    }

    var validateCf = function (value, element) {
        if (value == 'ND' || value == 'NA' || value == '') {
            return true;
        } else {
            var cf = value.toUpperCase();
            var cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
            var cfRegNew = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{2}[A-Z]{2}$/;
            if (!cfReg.test(cf) && !cfRegNew.test(cf))
                return false;
            var set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
            var s = 0;
            for (i = 1; i <= 13; i += 2)
                s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
            for (i = 0; i <= 14; i += 2)
                s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
            if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0))
                return false;

            return true;
        }
    }

    var validateISS = function (value, element) {
        if (value == '') {
            return true;
        } else {
            return true;
        }
    }

    var validateSpanishTaxCodes = function (value, element) {
        if (value == 'ND' || value == 'NA' || value == '') {
            return true;
        } else {
            var validateDNI = function (dni) {
                var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
                var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

                return letter == dni.charAt(8);
            };

            var validateNIE = function (nie) {
                // Change the initial letter for the corresponding number and validate as DNI
                var nie_prefix = nie.charAt(0);

                switch (nie_prefix) {
                    case 'X':
                        nie_prefix = 0;
                        break;
                    case 'Y':
                        nie_prefix = 1;
                        break;
                    case 'Z':
                        nie_prefix = 2;
                        break;
                }

                return validateDNI(nie_prefix + nie.substr(1));
            };

            var valid = false;
            var str = value.toUpperCase();
            var DNI_REGEX = /^(\d{8})([A-Z])$/;
            var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
            var type = '';
            if (str.match(DNI_REGEX)) {
                type = 'dni';
            }
            if (str.match(NIE_REGEX)) {
                type = 'nie';
            }
            switch (type) {
                case 'dni':
                    valid = validateDNI(str);
                    break;
                case 'nie':
                    valid = validateNIE(str);
                    break;
            }

            return valid;
        }
    }

    var validatePiva = function (value, element) {
        var pi = value;
        if (pi.length == 0) {
            return true;
        } else {
            var piReg = /\d{11}/;
            if (!piReg.test(pi))
                return false;
            var s = 0;
            for (i = 0; i <= 9; i += 2)
                s += pi.charCodeAt(i) - '0'.charCodeAt(0);
            for (i = 1; i <= 9; i += 2) {
                c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
                if (c > 9)
                    c = c - 9;
                s += c;
            }
            if ((10 - s % 10) % 10 != pi.charCodeAt(10) - '0'.charCodeAt(0))
                return false;
            return true;
        }
    }

    var validateEmailUnique = function (value, element) {
        if (value != '') {
            var $result = false;
            var $csrfToken = $('meta[name="csrf-token"]').attr('content');
            var $baseUrl = $('meta[name="base-url"]').attr('content');
            $.ajax({
                    type: 'POST',
                    url: $baseUrl + '/clients/verifyEmail',
                    async: false,
                    data: {
                        email: value,
                        _token: $csrfToken
                    }
                })
                .done(function (data) {
                    if (eval(data)) {
                        $result = true;
                    } else {
                        $result = false;
                    }
                })
                .fail(function (data) {
                    $result = false;
                });

            return $result;
        } else {
            return true;
        }
    }

    var validateOperatorUsername = function (value, element) {
        var $result = false;
        var $csrfToken = $('meta[name="csrf-token"]').attr('content');
        var $baseUrl = $('meta[name="base-url"]').attr('content');

        $.ajax({
                type: 'POST',
                url: $baseUrl + '/operator/checkIfOperatorExists',
                async: false,
                data: {
                    username: value,
                    _token: $csrfToken
                }
            })
            .done(function (data) {
                if (eval(data)) {
                    $result = true;
                } else {
                    $result = false;
                }
            })
            .fail(function (data) {
                $result = false;
                $('.username').addClass('error');
            });

        return $result;
    }

    var validateSpecialCharacters = function (value, element) {
        var specialChars = /['!@#$%^&*\)\(+=_-]/;

        if (!specialChars.test(value))
            return true;
        else
            return false;

    }


    var validateCAP = function (value, element) {
        var pattern4 = /^\d{4}$/;
        var pattern43 = /^\d{4}-\d{3}$/;
        var pattern5 = /^\d{5}$/;
        if (pattern4.test(value) || pattern5.test(value) || pattern43.test(value)) {
            return true;
        } else {
            return false;
        }
    }

    var validateFilledWithTrue = function (value, element) {
        if (value == 'true') {
            return true;
        } else {
            return false;
        }
    }

    var validateFilledWithFalse = function (value, element) {
        var valid = true;
        if (value == 'false') {
            bootbox.confirm({
                message: translations['interface.icare4.mailing.confirmWithFalse'],
                buttons: {
                    confirm: {
                        label: translations['interface.icare4.yes'],
                        className: 'btn btn-primary'
                    },
                    cancel: {
                        label: translations['interface.icare4.no'],
                        className: 'btn btn-primary'
                    }
                },
                callback: function (result) {
                    if (result === true) {
                        $('[name="' + element.name + '"]').removeClass('confirm-with-false');
                        $(element).parents('form').find('button[type="submit"]').click();
                    }
                }
            });
            valid = false;
        }
        return valid;
    }

    $.validator.addMethod("date-ita", validateDate);
    $.validator.addMethod("datetime-ita", validateDatetime);
    $.validator.addMethod("datepicker", validateDate);
    $.validator.addMethod("currency", validateCurrency);
    $.validator.addMethod("telephone", validateTelephone);
    $.validator.addMethod("equal", validateEqual);
    $.validator.addMethod("cf", validateCf);
    $.validator.addMethod("nif", validateSpanishTaxCodes);
    $.validator.addMethod("iss", validateISS);
    $.validator.addMethod("piva", validatePiva);
    $.validator.addMethod("email-unique", validateEmailUnique);
    $.validator.addMethod("username-unique", validateOperatorUsername);
    $.validator.addMethod("filled-with-true", validateFilledWithTrue);
    $.validator.addMethod("special-characters", validateSpecialCharacters);
    $.validator.addMethod("cap", validateCAP);
    $.validator.addMethod("confirm-with-false", validateFilledWithFalse);
    $.extend($.validator.messages, {
        'min': jQuery.validator.format(translations['interface.icare4.validation.messages.min']),
        'max': jQuery.validator.format(translations['interface.icare4.validation.messages.max']),
        'datetime-ita': translations['interface.icare4.validation.messages.datetimeIta'],
        'date-ita': translations['interface.icare4.validation.messages.dateIta'],
        datepicker: translations['interface.icare4.validation.messages.datepicker'],
        required: translations['interface.icare4.validation.messages.required'],
        email: translations['interface.icare4.validation.messages.email'],
        currency: translations['interface.icare4.validation.messages.currency'],
        telephone: translations['interface.icare4.validation.messages.telephone'],
        cf: translations['interface.icare4.validation.messages.cf'],
        nif: translations['interface.icare4.validation.messages.nif'],
        piva: translations['interface.icare4.validation.messages.piva'],
        equal: translations['interface.icare4.validation.messages.equal'],
        'email-unique': translations['interface.icare4.validation.messages.emailUnique'],
        'username-unique': translations['interface.icare4.validation.messages.usernameUnique'],
        'filled-with-true': translations['interface.icare4.validation.messages.filledWithTrue'],
        'special-characters': translations['interface.icare4.validation.messages.specialCharacters'],
        cap: translations['interface.icare4.validation.messages.cap'],
        'confirm-with-false': ''
    });


    $('.open-report-pdf:not([disabled])').click(function () {
        bootbox.dialog({
            animate: false,
            className: "pdf-modal-dialog",
            message: '<div class="text-center pdf-generation-in-progress mtop20 mbottom20"><i class="fa fa-spin fa-spinner"></i> ' + translations['interface.icare4.report.dialog.generationInProgress'] + ' ...</div><iframe class="hide iframe-pdf" src="' + $(this).attr('data-pdf-url') + '"></iframe>',
            closeButton: true,
            title: translations['interface.icare4.report.dialog.title']
        });
        $('.pdf-modal-dialog iframe').css('height', $(window).height() - 200);
        $('iframe').on("load", function () {
            $(this).removeClass('hide');
            $('.pdf-generation-in-progress').addClass('hide');
        });
    });

    $('.mail-btn:not([disabled])').click(function () {
        var clientEmail = $(this).data("client");
        var businessEmail = $(this).data("business");
        var otherBusinessEmail = $(this).data("other-business");
        var checkupId = $(this).data("id");
        var url = $(this).data("url");
        bootbox.dialog({
            animate: false,
            message: '<div class="modal-body"><div class="question-wrapper form-field"><div class="question-values"><div class="row checkbox mail-addresses"><div class="row ' + (clientEmail == '' ? 'hide' : '') + '"><div class="col-md-12 col-xs-12"><div class="col-md-1 col-xs-2"><label class=" question-value"><input type="checkbox" name="clientEmail" class="client-email"><span class="cr"><i class="cr-icon fa fa-check"></i></span></label></div><div class="col-md-11 col-xs-10">' + clientEmail + '</div></div></div><div class="row mtop20"><div class="col-md-12 col-xs-12"><div class="col-md-1 col-xs-2"><label class=" question-value"><input type="checkbox" name="businessEmail" class="business-email"><span class="cr"><i class="cr-icon fa fa-check"></i></span></label></div><div class="col-md-11 col-xs-10">' + businessEmail + '</div></div></div>' + (otherBusinessEmail ? '<div class="row mtop20"><div class="col-md-12 col-xs-12"><div class="col-md-1 col-xs-2"><label class="question-value"><input type="checkbox" name=otherBusinessEmail" class="other-business-email"><span class="cr"><i class="cr-icon fa fa-check"></i></span></label></div><div class="col-md-11 col-xs-10">' + otherBusinessEmail + '</div></div></div>' : '') + '<div class="row mtop20"><div class="col-md-12 col-xs-12"><div class="col-md-1 col-xs-2 mtop5"><label class=" question-value"><input type="checkbox" name="customEmail" class="custom-email"><span class="cr"><i class="cr-icon fa fa-check"></i></span></label></div><div class="col-md-11 col-xs-10"><input type="text" class="form-control new-email"/></div></div></div></div></div></div></div>',
            closeButton: true,
            title: translations['interface.icare4.email.dialog.title'],
            buttons: {
                send: {
                    label: translations['interface.icare4.buttons.send'],
                    size: 'large',
                    className: "btn btn-success",
                    callback: function () {

                        var emails = new Array();
                        if ($('.client-email').is(':checked')) {
                            emails.push(clientEmail);
                        }
                        if ($('.business-email').is(':checked')) {
                            emails.push(businessEmail);
                        }
                        if ($('.other-business-email').is(':checked')) {
                            emails.push(otherBusinessEmail);
                        }
                        if ($('.custom-email').is(':checked')) {
                            var newEmail = $('.new-email').val();
                            if (newEmail.indexOf('@') === -1) {
                                bootbox.alert({
                                    message: translations['interface.icare4.validation.messages.email'],
                                    animate: false
                                });
                                return;
                            } else if (newEmail != '') {
                                emails.push(newEmail);
                            } else {
                                bootbox.alert({
                                    message: translations['interface.icare4.validation.messages.email'],
                                    animate: false
                                });
                                return;
                            }
                        }

                        if (!$('.client-email').is(':checked') && !$('.business-email').is(':checked') && !$('.custom-email').is(':checked')) {
                            bootbox.alert({
                                message: translations['interface.icare4.email.messages.nothingSelected'],
                                animate: false
                            });
                            return;
                        }
                        var $csrfToken = $('meta[name="csrf-token"]').attr('content');
                        $.post(url, {
                                id: checkupId,
                                emails: emails,
                                _token: $csrfToken
                            })
                            .done(function (data) {
                                bootbox.alert({
                                    message: translations['interface.icare4.email.messages.mailSent'],
                                    animate: false
                                });
                            })
                            .fail(function (data) {
                                bootbox.alert({
                                    message: translations['interface.icare4.email.messages.mailError'],
                                    animate: false
                                });
                            });
                        $('.modal').modal('hide');
                    }
                }
            }
        });
    });

    $('.require-report-btn:not([disabled]').click(function () {
        var savedCheckupId = $(this).data("saved-checkup-id");
        var url = $(this).data("url");
        var element = $(this);
        if (!$(this).hasClass('report-pending')) {
            bootbox.dialog({
                animate: false,
                message: '<div class="modal-body"><h2>Richiedi l\'interpretazione ad un cardiologo che eseguirà l\'analisi in pochi minuti</h2><br/><br/><h3>Note importanti</h3>L’ECG mono-derivazione fornisce una serie di informazioni, utili specialmente nelle fasi di screening e prevenzione primaria. Con una traccia monoderivazione, è possibile individuare e descrivere frequenza e ritmo cardiaco del soggetto sottoposto a monitoraggio. Analizzando la striscia prodotta dalla rilevazione, è possibile differenziare un ritmo sinusale da un’aritmia sopraventricolare (quali ad esempio fibrillazione atriale, flutter atriale, tachiacardia sopraventricolare) o ventricolare (tachicardia ventricolare, fibrillazione ventricolare). E’ inoltre possibile identificare la presenza di battiti extrasistolici e di blocchi atrio-ventricolari. Con questo esame NON E’ invece POSSIBILE esprimersi sulla ripolarizzazione, ovvero su aspetti morfologici di ischemia o di infarto, per il quale l’unico strumento correttamente e legalmente valido resta l’esecuzione di un elettrocardiogramma a 12 derivazioni standard.</div>',
                closeButton: true,
                title: 'Richiedi interpretazione',
                buttons: {
                    send: {
                        label: 'Richiedi',
                        size: 'large',
                        className: "btn btn-success",
                        callback: function () {

                            var $csrfToken = $('meta[name="csrf-token"]').attr('content');
                            $.post(url, {
                                    savedCheckupId: savedCheckupId,
                                    _token: $csrfToken
                                })
                                .done(function (data) {
                                    bootbox.alert({
                                        message: 'Interpretazione richiesta con successo',
                                        animate: false
                                    });
                                    element.addClass('report-pending');
                                    element.find('.action-label').html('Interpretazione in corso');
                                })
                                .fail(function (data) {
                                    bootbox.alert({
                                        message: 'Errore nella richiesta dell\'interpretazione',
                                        animate: false
                                    });
                                });
                            $('.modal').modal('hide');
                        }
                    }
                }
            });
        }
    });

    $('.question-help-button').click(function () {
        bootbox.alert($(this).find('.question-help-text').html());
    });

    var backgroundColor = $('.score-color').css('background-color');

    $('.checkup-results .knob').trigger('configure', {
        "fgColor": backgroundColor,
        'bgColor': '#e4e4e4'
    });

    $(".show-section").click(function () {
        $($(this).data('target')).removeClass('hide');
    });

    $(".hide-section").click(function () {
        $($(this).data('target')).addClass('hide');
    });

    $('.submit-form').click(function () {
        $($(this).data('target')).submit();

    });

    $('.checkup-results, .checkup').closest('body').find('a').each(function (i) {
        //avoid alert for internal link
        if ((this.pathname != window.location.pathname || this.protocol != window.location.protocol || this.host != window.location.host) && this.host != '') {
            $(this).data('url', $(this).attr('href'));
            $(this).addClass('quit-checkup');
        }
    });

    $('.quit-checkup').click(function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        bootbox.confirm({
            animate: false,
            buttons: {
                confirm: {
                    label: translations['interface.icare4.yes'],
                    className: 'btn btn-primary'
                },
                cancel: {
                    label: translations['interface.icare4.no'],
                    className: 'btn btn-primary'
                }
            },
            message: translations['interface.icare4.checkup.messages.quitCheckup'],
            callback: function (result) {
                if (result) {
                    window.location.replace(url);
                }
            }
        });
    });

    calculateAge = function (birthdayTimestamp) { // birthday is a date
        var ageDifMs = Date.now() - birthdayTimestamp;
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    $('.v-navbar').click(function () {
        $(document).scrollTop(0);
    });
});